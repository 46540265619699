<template>
  <component :is="userData === undefined ? 'div' : 'b-card'">
    <!-- Alert: No item found -->
    <b-alert
      variant="danger"
      :show="userData === undefined"
    >
      <h4 class="alert-heading">
        {{ $t('fetchingUserError') }}
      </h4>
      <div class="alert-body">
        {{ $t('noUserFound') }}
        <b-link
          class="alert-link"
          :to="{ name: 'apps-users-list'}"
        >
          {{ $t('userList') }}
        </b-link>
        {{ $t('forOtherUsers') }}
      </div>
    </b-alert>

    <b-tabs
      v-if="userData"
      pills
    >
      <!-- Tab: Account -->
      <b-tab active>
        <template #title>
          <feather-icon
            icon="UserIcon"
            size="16"
            class="mr-0 mr-sm-50"
          />
          <span class="d-none d-sm-inline">
            {{ $t('accountInfo') }}
          </span>
        </template>
        <user-edit-tab-account
          :user-data="userData"
          class="mt-2 pt-75"
          disabled-upload-avatar
          disabled-email-verify
          @updateData="updateUser"
          @updateProfile="fetchUser"
          @verify="verifyEmail"
        />
      </b-tab>

      <!-- Tab: Information -->
      <b-tab>
        <template #title>
          <feather-icon
            icon="InfoIcon"
            size="16"
            class="mr-0 mr-sm-50"
          />
          <span class="d-none d-sm-inline">
            {{ $t('companyInfo') }}
          </span>
        </template>
        <user-edit-tab-company-info
          :user-data="userData"
          class="mt-2 pt-75"
          @updateData="updateUser"
        />
      </b-tab>

      <b-tab>
        <template #title>
          <feather-icon
            icon="InfoIcon"
            size="16"
            class="mr-0 mr-sm-50"
          />
          <span class="d-none d-sm-inline">
            {{ $t('agreement') }}
          </span>
        </template>
        <user-edit-tab-agreement
          :user-data="userData"
          class="mt-2 mb-0 pt-75"
        />
        <user-edit-tab-agreement-sign
          :user-data="userData"
        />
      </b-tab>

      <!-- Tab: Social -->
      <b-tab>
        <template #title>
          <feather-icon
            icon="KeyIcon"
            size="16"
            class="mr-0 mr-sm-50"
          />
          <span class="d-none d-sm-inline">
            {{ $t('changePassword') }}
          </span>
        </template>
        <user-edit-tab-change-password
          class="mt-2 pt-75"
          @updateData="updateUser"
        />
      </b-tab>
    </b-tabs>
  </component>
</template>

<script>
import {
  BTab, BTabs, BCard, BAlert, BLink,
} from 'bootstrap-vue';
import { onMounted } from '@vue/composition-api';
import UserEditTabAgreement from '@/views/apps/user/users-edit/UserEditTabAgreement.vue';
import UserEditTabAgreementSign from '@/views/apps/user/users-edit/UserEditTabAgreementSign.vue';
import UserEditTabAccount from './UserEditTabAccount.vue';
import UserEditTabCompanyInfo from './UserEditTabCompanyInfo.vue';
import UserEditTabChangePassword from './UserEditTabChangePassword.vue';
import useUserEdit from './useUserEdit';

export default {
  components: {
    UserEditTabAgreementSign,
    BTab,
    BTabs,
    BCard,
    BAlert,
    BLink,

    UserEditTabAccount,
    UserEditTabCompanyInfo,
    UserEditTabAgreement,
    UserEditTabChangePassword,
  },
  setup() {
    const {
      userData,

      resolveUserActivationVariant,
      resolveUserActivationText,

      fetchUser,
      updateUser,
      verifyEmail,
    } = useUserEdit();

    onMounted(async () => {
      if (userData) {
        await fetchUser();
      }
    });

    return {
      userData,
      updateUser,
      verifyEmail,

      fetchUser,
      resolveUserActivationVariant,
      resolveUserActivationText,
    };
  },
};
</script>

<style>

</style>
