import { computed, ref } from '@vue/composition-api';
import store from '@/store';
import router from '@/router';

// Notification
import { useToast } from 'vue-toastification/composition';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';

export default function useUserEdit() {
  const toast = useToast();

  const userId = router.currentRoute.params.id;

  const userData = ref(store.state.users.currentUser);

  const isAllowAgreement = computed(() => Object.values(store.state.users.currentUser.company).filter(val => !val).length === 0);

  const fetchUser = async () => {
    try {
      await store.dispatch('users/fetchUserById', userId);
      userData.value = store.state.users.currentUser;
    } catch (err) {
      toast({
        component: ToastificationContent,
        props: {
          title: 'Error fetch user',
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      });
    }
  };

  const verifyEmail = async () => {
    try {
        toast({
            component: ToastificationContent,
            props: {
                title: `Account activation link sending to ${userData.value?.email}!`,
                icon: 'CheckCircleIcon',
                variant: 'info',
            },
        });
        await store.dispatch('users/verifyEmail', userId);
        toast({
            component: ToastificationContent,
            props: {
                title: `Account activation link sent to ${userData.value?.email}! Please follow the link in the email to proceed with your registration.`,
                icon: 'CheckCircleIcon',
                variant: 'success',
            },
        });
    } catch (err) {
        toast({
            component: ToastificationContent,
            props: {
                title: 'Error fetch user',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
            },
        });
    }
  };

  const updateUser = async updatableData => {
    try {
      const data = {
        data: updatableData,
        userId: userData.value.id,
      };
      await store.dispatch('users/update', data);
      await fetchUser();

      toast({
        component: ToastificationContent,
        props: {
          title: 'User saved successfully',
          icon: 'CheckCircleIcon',
          variant: 'success',
        },
      });
    } catch (err) {
      toast({
        component: ToastificationContent,
        props: {
          title: 'Error updating user',
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      });
    }
  };

  return {
    userData,
    isAllowAgreement,

    fetchUser,
    verifyEmail,
    updateUser,
  };
}
